$redColor: #e01a4f;
$greenColor: #46bfa5;
$lightBlackColor: #bdbdc0;
$whiteColor: #fff;
$grayCancel: #535252;
$borderDelete: #dc3545;

.fw-1 {
  font-weight: 100 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.socialHover {
  &:hover {
    color: $redColor !important;
  }
}

.colorLogo {
  color: $redColor !important;
}

.top-1 {
  top: 1px;
}

.crossCircle {
  float: right;
  color: $whiteColor;
  margin-right: 5px;
  font-size: 18px !important;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}


.skills-view {
  height: auto;
  padding: 5px 23px;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  background: #f7f7f7;
  font-size: 13px;
  border: 1px solid #e7e7e7;
}

.addmore {
  font-size: 25px;
  color: #bdbdc0;
  cursor: pointer;
}

.addmore_social {
  padding: 12px 0;
}

.disopt {
  opacity: 0.7;
}

.greenBg {
  .puoHeader {
    background: $greenColor !important;
  }

  .confirm {
    color: $greenColor !important;
    border-color: $greenColor !important;
  }

  .confirm:hover {
    color: #fff !important;
    border-color: $greenColor !important;
    background: $greenColor !important;
  }
}

.greenColor {
  color: $greenColor !important;
}

.btn-save {
  border-color: $greenColor;
  color: $greenColor;
}

.btn-save:hover {
  background-color: $greenColor;
}

.btn-save:active {
  background-color: $greenColor !important;
}

.nCancilBtn {
  border: 1px solid#9e9e9e;
  color: #535252;
  border-color: #9e9e9e;
  padding: 8px 15px;
  float: left;
  border-radius: 30px;
  background-color: $whiteColor;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  width: 80px;
  text-align: center;
}

.nCancilBtn:hover {
  background-color: #535252 !important;
  border-color: #535252 !important;
  color: $whiteColor;
}

.nCancilBtn:active {
  background-color: $grayCancel !important;
  border-color: $grayCancel !important;
  color: $whiteColor !important;
}

.formsubmitbtn {
  float: right;
}

.btn-delete {
  border: 1px solid $borderDelete;
  color: $borderDelete;
  padding: 8px 15px;
  border-radius: 30px;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
  min-width: 76px;
}

.btn-delete:hover {
  background-color: $redColor;
  color: $whiteColor;
}

.saveBtn,
.newSubmit {
  border: 1px solid #46bfa5;
  min-width: 80px;
  text-align: center;
  float: left;
  border-radius: 30px;
  padding: 8px 15px;
  color: #46bfa5;
  font-weight: 600 !important;
  background-color: #fff;
  font-size: 12px;
  cursor: pointer !important;
}

.saveBtn:hover {
  background-color: #46bfa5;
  color: #fff;
}

.saveBtn:disabled {
  background-color: #dadada;
  color: #919191;
  border-color: #dadada;
}

.newSubmit:hover {
  background-color: $greenColor;
  color: $whiteColor;
}

.color-save {
  color: $greenColor;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.newSubmit:hover .color-save {
  color: $whiteColor;
  font-weight: bold;
}

.btn-center {
  margin: 0 auto;
}

.paddingset {
  padding: 8px 15px !important;
  cursor: pointer;
  font-size: 12px !important;
  min-width: 80px;
}

.align-center {
  text-align: center;
}

$redColor: #e01a4f;
$greenColor: #46bfa5;
$lightBlackColor: #bdbdc0;
$whiteColor: #fff;

.fw6 {
  font-weight: 600;
}

.socialHover {
  &:hover {
    color: $redColor !important;
  }
}

.crossCircle {
  float: right;
  color: $whiteColor;
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.centerText {
  display: flex;
  justify-content: center;

  &:hover a {
    color: #fff;
  }
}

.centerText a {
  font-weight: normal;
}

.centerText .saveBtn {
  height: 35.99px;
  line-height: 14px;
}

.addmore {
  font-size: 25px;
  color: #bdbdc0;
  cursor: pointer;
}

.addmore_social {
  padding: 12px 0;
}

.greenBg {
  .puoHeader {
    background: $greenColor !important;
  }

  .confirm {
    color: $greenColor !important;
    border-color: $greenColor !important;
  }

  .confirm:hover {
    color: #fff !important;
    border-color: $greenColor !important;
    background: $greenColor !important;
  }
}

.greenColor {
  color: $greenColor !important;
}

.text-link-hover {
  &:hover {
    text-decoration: underline;
  }
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 15px !important;
}

.textSettle {
  font-weight: 700;
  font-size: 20px;
}

.edit-icon,
.project-list-three-dot {
  color: $greenColor !important;
  cursor: pointer;
  font-size: 17px;

  &:hover {
    opacity: 0.7;
  }
}

.font-25 {
  font-size: 25px !important;
}

.delete-icon {
  cursor: pointer;
  color: $redColor !important;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
  }
}

.back-button {
  color: $greenColor;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  float: left;

  i {
    position: relative;
    top: 1px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.separator-tabs {
  border-bottom: 1px solid #d7d7d7;
}

.setsetClose,
.react-responsive-modal-closeButton {
  &:hover {
    opacity: 0.7;
  }
}

.createdBy {
  color: #000;
  text-transform: capitalize;
}

.greenBorder {
  background: $greenColor !important;
  height: 6px;
  margin-top: -6px;
}

.edit-icon {
  font-size: 13px;
}

.setClose:hover {
  opacity: 0.7;
}

.text-sucess {
  color: $greenColor;
  cursor: pointer;
}

.text-sucess:hover {
  opacity: 0.7;
  border-bottom: 1px solid$greenColor;
}

.assign {
  float: right;
}

.assign em {
  color: $greenColor;
}

.assign:hover {
  background: $greenColor;
  color: $whiteColor;
}

.assign:hover em {
  color: $whiteColor;
}

.flexboxMain {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.justificontent {
  justify-content: center;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 25px;
  // border: 1px solid#ddd;
  border-radius: 5px;
  cursor: pointer;
}

.icon-box:hover em {
  opacity: 0.7;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 20px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}

.MuiAccordionSummary-content {
  margin: 12px 0;
}

.topic-heading {
  font-size: 16px;
  font-weight: 600;
}

.perfect-shap {
  position: relative;
  background: #f7f7f7;
  margin-bottom: 7px;
  height: auto;
  border: 1px solid#ddd;
}

a.newClose.setcloser {
  top: 0;
  right: 0;
}

.newClose svg {
  width: 15px;
  fill: #000;
}

.align-end {
  justify-content: flex-end;
  margin-right: 3px;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.newClose:hover {
  background-color: #f0f0f0;
}

.newClose {
  position: absolute;
  top: 6px;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #fff;
  border: 1px solid#727070;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.greenSaper {
  position: absolute;
  top: -13px;
  right: 5px;
  background: #f0f0f0;
  padding: 5px;
  justify-content: center;
  border-radius: 50px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
}

.greenSaper:hover {
  color: #e01a4f8a;
}

.customechecks .dx-checkbox-icon {
  border: 2px solid$greenColor;
}

.customechecks .dx-checkbox-checked .dx-checkbox-icon {
  background-color: $greenColor !important;
}

.customechecks .dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  background-color: $greenColor !important;
}



.customechecks .dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: $greenColor !important;
}

.setmt {
  margin-bottom: 0.5rem;
}

span.newClose.setpoupinner {
  top: 16px;
  right: 22px;
}

.graybox {
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid#ddd;
  margin: 10px 0px 0px;
}

.graybox span {
  color: #565656;
  font-size: 14px;
  font-weight: 600;
}

.filterCate .dropdown-menu.show {
  height: 360px;
  overflow: auto;
}

.elipsis {
  background-color: #fff !important;
  color: gray !important;
  border-color: #fff !important;
  margin-right: 0;
  float: right;
  padding: 0 10px;
  position: relative;
  top: -3px;
  right: -11px;
}

.noborder {
  border: none !important;
  border-color: transparent !important;
}

.setfont {
  color: #fff;
  font-size: 0.8rem !important;
}

.setfont:hover {
  color: #fff;
}

.proptPupup,
.shade {
  transition: ease-in-out all 0.4s;
}

.pull_right {
  float: right !important;
}

.flext_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation {
  top: 33% !important;
  opacity: 1 !important;
}

.opacityAnimation {
  opacity: 0.7 !important;
}

.titleUser {
  font-weight: bold;
  color: $grayCancel;
  font-size: 14px;
  float: left;
  margin-left: 1px;
  text-transform: capitalize;

  &:hover {
    opacity: 0.7;
  }
}

.createEvent,
.createEventShade {
  opacity: 1 !important;
  top: 50% !important;
}

.showOpacity {
  opacity: 1 !important;
  top: 0px !important;
}

.createEventShade {
  top: 0px !important;
}

.d-icon {
  font-size: 18px;
}

.addCategory:hover .span-link {
  color: $greenColor;
}

.Engagevideo_description {
  font-size: 14px !important;
}

.commentBtn {
  font-size: 14px;
  color: #596a80;
  // margin-right: 5px;
  font-weight: 600;
}

.replaybtn {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  padding: 5px 10px 0px 10px;
  margin-right: -10px;
  color: #596a80;
}

.fa.project-list-three-dot {
  color: #747474 !important;
}

.noborder {
  border: none !important;
  border-color: transparent !important;
}

.setfont {
  color: #fff;
  font-size: 0.8rem !important;
}

.setfont:hover {
  color: #fff;
}

h2.secondryHeading {
  font-size: 20px;
  font-weight: bold;
}

.iconBox {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #46bfa5;
  border-radius: 50px;
  float: left;
  position: absolute;
  top: 16px;
  left: 8px;
}

.iconBox i {
  color: #fff;
  font-size: 30px;
}

.launchResorceBox {
  position: relative;
  padding: 15px;
}

.launchBoxRight {
  margin-left: 45px;
}

.launchBoxBody {
  border: 1px solid#ddd;
  border-radius: 60px;
  padding: 5px 10px;
  min-height: 54px;
  background-color: #fff;
}

.launchBoxRight h2 {
  font-size: 14px;
  text-transform: capitalize;
  margin: 4px 0px 0px 0px;
  font-weight: bold;
}

.launchBoxRight p {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;

}

.setboxIcon {
  width: 100%;
  text-align: right;
  position: absolute;
  display: flex;
  right: 35px;
  top: 24px;
  justify-content: flex-end;
}

a.cardtitle:hover p {
  color: #545454;
}

a.cardtitle:hover h2 {
  color: $greenColor;
}

.padderSlate {
  margin-top: 10px;
}


.shade.none.dip.showOpacity {
  opacity: 0.5 !important;
}

.flexBaseCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nomargin {
  margin: 0px !important;
}

.setbauu {
  border: none !important;
  z-index: 1000;
}

.newCancel {
  width: 80px;
  text-align: center;
}

.headingLaunchpad {
  text-align: center;
  font-weight: bold;
}

.m-nagitive {
  position: relative;
  top: -12px;
}

.prelative {
  position: relative;
  top: -2.5px;
}

.customeHight {
  height: 400px;
  object-fit: contain;
}

.sylogo {
  height: 15px;
  position: relative;
  top: -2px;
  left: -2px;
}

.buttontop:hover {
  color: $greenColor;
}

.hoverGreen:hover {
  background: #46bfa5 !important;
  color: #fff !important;
}

.hoverGreen:hover em {
  color: #fff !important;
}

.setter .dropdown-menu.show {
  transform: translate3d(-80%, 26px, 0px) !important;
}

a.newhoverpart:hover div {
  color: #000 !important;
}

.filter_community_dropdown.dropdown-toggle.btn.btn-secondary:active {
  border-color: $greenColor !important;
  background-color: $greenColor !important;
  color: $whiteColor;
}

.margincustome {
  margin-left: 30px;
}

.height155 {
  overflow-x: hidden;
  overflow-y: auto;
  height: 155px;
}

.height190 {
  height: 190px;
  background: #ffffff;
}

span.colorfont {
  background-color: rgb(241, 242, 244) !important;
  border-radius: 0px 5px 5px 0px;
  margin-left: 8px;
  padding: 5px 10px;
  display: inline-block;
  width: 142px;
}

.padderCustomeButton {
  padding: 8px 15px !important;
  min-width: 95px;
  font-size: 12px !important;
}

.paddItems {
  padding: 20px 20px;
}

.profilePopWidth {
  max-width: 650px !important;
}

.MuiIconButton-label {
  position: relative;
  height: 30px;
}

.toggleslack span.MuiSwitch-thumb {
  top: -11%;
}

.setw-86 {
  width: 86px;
}

.conformalert {
  width: 95%;
}

.padtopbot5 {
  padding: 5px 0px;
}

.padertopbottom10 {
  padding: 20px 15px;
  width: 100%;
}

img.iconsLinks {
  width: 30px;
}

.set3Cols {
  width: 40%;
}

.droppable.set3Cols .col-12 {
  padding: 0px !important;
}

.padderSlate,
.padderSlate-res h2 {
  font-size: 20px;
  font-weight: 700;
}

.np-0 {
  padding-right: 56px;
}

.dragsvg_icon {
  fill: #000;
}

.imgFigure {
  position: absolute;
  top: 50%;
  right: 38px;
  opacity: 0.4;
  transform: translate(0px, -50%);
}

.imgFigure img {
  width: 20px;
}

.resourcelist {
  width: 75px;
  height: 75px;
  left: 0px;
  top: 15px;
}

.datelist {
  font-size: 11px;
  opacity: 0.9;
}

.resourcelisttext {
  margin-left: 60px;
}

.resourceHeading {
  display: flex;
  float: left;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ADB8C4;
  border-bottom: 1px solid #ADB8C4;
  padding-bottom: 12px;
}

.resourceheadingText {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.resources {
  top: 33px;
  right: 25px;
}

p.NoDataTExt {
  text-align: center;
  color: #e00;
  font-size: 18px;
  margin-top: 70px;
}

p.NoDataTExt {
  text-align: center;
  color: #000;
  font-size: 18px;
  margin-top: 70px;
  display: block;
  width: 100%;
}

h2.chlar {
  margin-top: 12px;
}

.launchBoxRight.resourcelisttext {
  min-height: 58px;
  margin-right: 67px;
}



/*----new-setting-design---*/
.shadeGray {
  background-color: #fafafa;
  border: 1px solid #ededed;
}

.settinglogoHeading {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

.pannelSettingHeader {
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ml-60 {
  margin-left: 60px;
}

.pannelSettingBody {
  border-radius: 5px;
  padding: 10px 15px;
  height: 335px;
}

.formGridSetting input {
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.formGridSetting {
  position: relative;
  text-align: center;
  padding: 25px 0px;
  display: block;
}

.formGridSetting span {
  font-size: 135px;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.formGridSetting b {
  display: block;
}

.formGridSetting p {
  font-size: 16px;
  margin: 5px 0px;
  padding: 0;
}

.bioQuationGrid.mt-2 p {
  padding: 0px;
  margin: 0;
}

.bioQuationGrid input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid#ddd;
  outline: none;
}

.settingpannel {
  padding: 20px 0px 50px;
}

.communitySavebtn {
  height: 35px;
  line-height: 17px;
}

/*tablenew*/
.newTable {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}

table.managerUser {
  width: 5840px;
  margin-bottom: 0;
  display: block;
  min-height: 300px !important;
}

.lauchtabview {
  display: inline-block;
  background: #E3E3E5;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 30px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #303030;
}

table.managerUser thead {
  background: #eeeef0;
  display: block;
}

table.adminuser,
table.almuniuser {
  width: 100%;
  margin-bottom: 0;
  display: block;
  min-height: 330px;
}

table.adminuser thead,
table.almuniuser thead {
  background: #eeeef0;
  display: inline-block;
  width: 100%;
}

table.almuniuser .w115 {
  width: 15% !important;
}

table.almuniuser .w130 {
  width: 15% !important;
}

table.almuniuser .w150 {
  width: 20% !important;
}

table.almuniuser .w250 {
  width: 25% !important;
}

table.adminuser thead tr td,
table.almuniuser thead tr td {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 24px;
  color: #5e5e5e;
  text-transform: capitalize;
  font-weight: 900;
  width: 289px;
}

.MenuItem legend {
  width: 0 !important;
}

table.adminuser tbody,
table.almuniuser tbody {
  border-left: 1px solid#ddd;
  border-right: 1px solid#e7e7e7ab;
}

.adminuser .w115 {
  width: 20% !important;
}

.adminuser .w130 {
  width: 15% !important;
}

.adminuser .w250 {
  width: 40% !important;
}

.adminuser .w150 {
  width: 25% !important;
}

table.managerUser thead tr td {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 24px;
  color: #5e5e5e;
  text-transform: capitalize;
  font-weight: 900;
  width: 190px;
  display: inline-block;
}

table.managerUser tbody tr td {
  border-bottom: 1px solid#ddd !important;
  padding: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #6c757e;
  font-weight: 900;
  width: 190px;
  white-space: break-spaces;
}

.manageUserDotted {
  width: 50px !important;
}

.w-inherit {
  width: inherit !important;
}

table.managerUser tbody {
  border-left: 1px solid#ddd;
  border-right: 1px solid#e7e7e7ab;
}

.relativeTableCol {
  position: relative;
}


.relativeTableCol .dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.relativeTableCol button {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}



.nomargin .dropdown-menu {
  padding: 0px !important;
}

th.startdate.setWidth {
  width: 100px;
}

th.enddate.setnewend {
  width: 125px;
}

/*tablenew*/
.cummunityPannel textarea {
  width: 100%;
  height: 150px;
  border: 1px solid#ddd;
  border-radius: 5px;
  font-size: 13px;
}

.cummunityPannel p {
  padding: 0px;
  margin: 0;
}

.settingFigure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #fff;
}

.settingFigure img {
  width: 200px;
}

.customeClose {
  z-index: 99;
}

.reltext {
  display: none;
}

img.frontimg {
  height: 280px;
  object-fit: contain;
}

span.newClose.customeClose {
  padding: 3px;
}

.centertax {
  height: 279px;
}

.centertax img {
  object-fit: contain;
}

.closeQestion {
  height: 41px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e01a4f;
  color: #fff;
  position: relative;
  top: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.closeQestion svg {
  fill: #fff !important;
}

.closeQestion:hover svg {
  opacity: 0.7;
}

.autoHeight {
  height: auto;
}

.wfFlexBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day--disabled {
  opacity: 0.6;
}

input.input-text.fadeinput {
  opacity: 0.5;
  background: #ddd;
}

.mgt-1 {
  margin-top: 1px;
}

.chekall {
  padding: 2px 0;
  margin-top: 2px !important;

  &:hover {
    color: $greenColor;
  }
}

.border_radius {
  border-radius: 6px !important;
}

.modal-body input,
.modal-body select,
.modal-body #datepicker,
.modal-body textarea {
  padding: 8px !important;
}

.projectAddpopupform select.form-control.newformInput {
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 13px;
  padding: 8px;
  margin-bottom: 5px;
  background-color: #fff;

  &:focus {
    border-color: $greenColor;
    outline: none;
  }
}

.viewlogbox {
  height: calc(100vh - 203px) !important;
  overflow: auto;
}

.fxcenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

.setercol {
  float: left;
  width: 423px;
}

.buttonbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mmhistory {
  height: 400px;
}

.w110 {
  width: 110px !important;
}

.w120 {
  width: 120px !important;
}

.w130 {
  width: 130px !important;
}


.w100 {
  width: 100px !important;
}



.w150 {
  width: 150px !important;
}

.w160 {
  width: 160px !important;
}

.w170 {
  width: 170px !important;
}

.w200 {
  width: 200px !important;
}

.w250 {
  width: 250px !important;
}

.setPoupTable {
  height: 370px;
  min-height: 300px;
  overflow: auto;
}

.pouptable {
  padding: 20px 0px;
}

.historypopup {
  margin-right: 4px;
  margin-left: 3px;
}

.setmaxlogtable {
  max-width: 1100px !important;
}

.setPoupTable {
  height: 370px;
  min-height: 300px;
  overflow: auto;
}

.pouptable {
  padding: 20px 0px;
}

.historypopup {
  margin-right: 4px;
  margin-left: 3px;
}

.setmaxlogtable {
  max-width: 1100px !important;
}

.managerUser p {
  margin: 0px;
  padding: 0px;
}

/* width */
.vertical::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.vertical::-webkit-scrollbar-track {
  box-shadow: #f1f1f1;
  border-radius: 10px;
  padding: 5px;
}

/* Handle */
.vertical::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  height: 2px;
  width: 5px;
}

/* Handle on hover */
.vertical::-webkit-scrollbar-thumb:hover {
  background: #f9f9f9;
}

.engageMargintop {
  margin-top: 5px;
}

/*--newtable--*/
.logTable {
  width: 100%;
}

.logTable tr td,
.logTable tr th {
  padding: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #6c757e;
  font-weight: 900;
}

.logTable p {
  margin: 0px;
  padding: 0px;
}

.logTable thead {
  background: #eeeef0;
  padding: 15px;
}

.logTable tbody tr td {
  border-bottom: 1px solid #ddd;
}

.logTable tbody {
  border-left: 1px solid #ddd;
  border-right: 1px solid hsla(0, 0%, 90.6%, 0.670588);
}

.school-input input {
  height: auto !important;
}

.setPoupTable {
  height: 370px;
  min-height: 300px;
  overflow: auto;
}

.pouptable {
  padding: 20px 0px;
}

.historypopup {
  margin-right: 4px;
  margin-left: 3px;
}

.setmaxlogtable {
  max-width: 1100px !important;
  min-width: 600px !important;
}

.userRadioBtn {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 80px;
}

.userRadioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.imgIcon {
  width: 53px;
}

.customeUserBtn {
  font-size: 12px;
  border: 1px solid#46BFA5;
  max-width: 80px;
  color: #46bfa5;
  font-weight: 400;
  padding: 8px 15px;
  border-radius: 50px;
  display: block;
  text-align: center;
}

.customeUserBtn:hover {
  background: #46bfa5;
  color: #fff;
}

.userRadioBtn input:checked~.customeUserBtn {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #6f6f6f;
}

.wrapper-stack {
  position: relative;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #eeeef0;
  z-index: 1;
}

.editmanagUsers.seterBox {
  max-width: 800px !important;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}

.colone-col {
  left: 0;
  width: 50px !important;
  z-index: 1;
}

.mstBox {
  display: flex;
  float: left;
  width: 20px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.mstBox .worksite.dropdown-menu.show {
  margin-top: 6px;
  margin-left: 16px;
}

.mbox {
  z-index: 2 !important;
}

div.worksite.dropdown-menu.show {
  padding: 0px;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-80px, 26px, 0px);
}

/*----new-setting-design---*/
.shadeGray {
  background-color: #fafafa;
}

.w110 {
  width: 110px !important;
}

.w120 {
  width: 120px !important;
}

.w130 {
  width: 130px !important;
}

.w150 {
  width: 150px !important;
}

.w160 {
  width: 160px !important;
}

.w170 {
  width: 170px !important;
}

.w200 {
  width: 200px !important;
}

.w300 {
  width: 300px !important;
}

.buttonbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.w250 {
  width: 250px !important;
}

/* width */
.vertical::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.vertical::-webkit-scrollbar-track {
  box-shadow: #f1f1f1;
  border-radius: 10px;
  padding: 5px;
}

/* Handle */
.vertical::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  height: 2px;
  width: 5px;
}

/* Handle on hover */
.vertical::-webkit-scrollbar-thumb:hover {
  background: #f9f9f9;
}

.engageMargintop {
  margin-top: 5px;
}

/*--newtable--*/
.logTable {
  width: 100%;
}

.logTable tr td,
.logTable tr th {
  padding: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #6c757e;
  font-weight: 900;
}

.logTable p {
  margin: 0px;
  padding: 0px;
}

.logTable thead {
  background: #eeeef0;
  padding: 15px;
}

.logTable tbody tr td {
  border-bottom: 1px solid #ddd;
}

.logTable tbody {
  border-left: 1px solid #ddd;
  border-right: 1px solid hsla(0, 0%, 90.6%, 0.670588);
}

.school-input input {
  height: auto !important;
}

.setPoupTable {
  height: 370px;
  min-height: 300px;
  overflow: auto;
}

.pouptable {
  padding: 20px 0px;
}

.historypopup {
  margin-right: 4px;
  margin-left: 3px;
}

.setmaxlogtable {
  max-width: 1100px !important;
}

.react-responsive-modal-modal {
  min-width: 600px;
}

.dx-texteditor.dx-editor-underlined.dx-show-clear-button .dx-texteditor-input {
  padding-left: 25px !important;
}

.dx-placeholder {
  left: 8px;
}

table.managerUser.manageuser thead tr td {
  background-color: #eeeef0 !important;
}

.w-300 {
  width: 300px !important;
}

td.w-inherit.mgt-1.sticky-col.first-col.left-fix-space {
  left: 300px;
}

.MenuItem {
  width: 100%;
}

.MenuItem .MuiInputBase-input {
  height: 11px !important;
}

.MuiIconButton-label {
  height: 23px;
}

.MuiFormLabel-root {
  font-size: 13px;
  position: relative;
  top: 14px;
}

.MuiAutocomplete-tag {
  margin: 2px !important;
}

.MuiChip-root {
  height: 28px !important;
}

.editMasterProfile .MuiInputLabel-root {
  display: none !important;
}

.MuiInputLabel-outlined {
  background-color: #fff !important;
  padding: 0px 5px !important;
}

.PrivateNotchedOutline-legendNotched-4 {
  width: 0 !important;
}

.medialeftImg {
  float: left;
}

.mediarightText {
  margin-left: 60px;
}

.arrow {
  display: none;
}

.newPhonefield {
  position: relative;
}

span.caret {
  position: absolute;
  top: 11px;
  left: 30px;
  pointer-events: none;
}

.newPhonefield .react-tel-input .flag-dropdown {
  width: 50px;
  border-radius: 7px 0 0 7px !important;
}

.newPhonefield input {
  width: 100% !important;
  height: 50px !important;
  padding-left: 60px !important;
  border-radius: 7px !important;
}

.newPhonefield input:focus,
.newPhonefield input:focus .react-tel-input .flag-dropdown {
  border-color: #46bfa5;
}

.newPhonefield .react-tel-input .selected-flag {
  width: 100%;
  padding: 6px;
  border-radius: 7px 0 0 7px !important;
}

.setPaddingboddy {
  padding: 20px;
}

.profileUserText {
  font-size: 16;
  font-weight: 400;
  margin-left: 8px;
  margin-bottom: 8px;
}

.profilefontSet {
  font-size: 16;
  font-weight: 400;
  margin-top: -10;
  margin-left: 8px;
  margin-bottom: 8px;
}

.profileTextinsent {
  font-weight: 700;
  font-size: 20px;
  margin-top: 48px;
}

.fontDepartment {
  font-size: 14px;
  font-weight: bold;
}

.squarebtn {
  border-radius: 6px;
}

.departmentdetailsPara p {
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bold;
}

.flextbodertable {
  padding: 10px 10px;
  border-bottom: 1px solid#ddd;
}

.departmentdetailsPara {
  margin-top: 5px;
}

/*setting-detail-css*/
.detailtext {
  margin-top: 5px;
  font-weight: bold;
  text-decoration: underline;
  font-size: 14px;
}

.groupopHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid#D9D9D9;
}

.gropPopheading {
  font-weight: bold;
  font-size: 16px;
}

.groupclose {
  cursor: pointer;
}

.viewsingle {
  padding: 15px;
}

.grpbox {
  width: 150px;
  font-weight: 400;
  font-size: 14px;
  color: #303030;
}

.linkdiv {
  display: flex;
  justify-content: space-between;
  background: rgba(246, 247, 248, 1);
  padding: 10px 15px;
}

.grp-single {
  font-weight: bold;
  font-size: 14px;
}


.grpview {
  display: flex;
  gap: 35px;
  position: relative;
  padding: 4px 0px;
}

.grpchildlist {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 134px;
  margin-left: 158px;
  padding: 8px 15px 0px;
  position: absolute;
  background-color: #fff;
  top: 21px;
  right: 0;
  color: #303030;
}


.grpchildlist ul {
  padding: 0;
}

.grpchildlist ul li {
  list-style: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0px;
}

.grpchildlist ul li:hover {
  background-color: #f0f0f0;
}

.editgroupdetailsIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 6px;
}

.viewbody-container {
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 212px;
  margin: 10px 0px;
  min-height: 212px;
}

.viewfooter {
  padding: 15px 15px;
  display: block;
  width: 100%;
  border-top: 1px solid #D9D9D9;
  margin-top: 20px;
  float: left;
}

.viewbody {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  border-bottom: 1px solid #EBEDF3;
  cursor: pointer;
}

.viewbody:hover {
  background-color: #f7f7f7;
}

.viewresource {
  color: #303030;
  font-weight: 700;
  font-size: 12px;
  width: 14%;
}

.viewdetailcontainer {
  width: 460px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 9999;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.groupLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #EBEDF3;
}

.allinked {
  color: #46BFA5;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-left: 28px;
  position: relative;
}

.grp-delete {
  border: 1px solid #E01A4F;
  color: #E01A4F;
  padding: 7px 25px;
  border-radius: 30px;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
}

.grp-delete:hover {
  background-color: #E01A4F;
  color: white;
}

.grpcontainer {
  width: 626px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%)
}

.grpbody {
  padding: 15px;
}






.grpmain {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.grp-header {
  width: 175px;
  height: 24px;
  color: #303030;
  font-weight: 600;
  line-height: 24px;
  font-size: 20px;
}



.grp-text {
  font-weight: bold;
  font-size: 14px;
  color: #303030;
  margin-bottom: 16px;
}



.grpnametext input {
  border: 1px solid #ADB8C480;
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.grpnametext input:focus {
  border-color: #ADB8C480 !important;
}

.grpnametext label {
  font-weight: 600;
  font-size: 14px;
}

.grpfooter {
  padding: 15px 0px;
  display: block;
  width: 100%;
  margin-top: 10px;
  float: left;
}





.grpcross {
  cursor: pointer;
  width: 20px;
  height: 20px;
}




.resource {
  font-size: 15px;
  color: #303030;
  font-weight: 600;
  margin-top: 3px;
  width: 82%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}



.showuser-container {
  width: 460px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 5px;
}

.user-grp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #D9D9D9;
}

.grp-back {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grp-back img {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  position: relative;
  left: 3px;
}

.cr {
  cursor: pointer;
}

.grpname {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
}

.grptext {
  padding: 20px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #303030;

}

.underlinegrp {
  border-bottom: 1px solid #303030;
}

.userscount {
  background: #F6F7F8;
  border: 1px solid #EBEDF3;
  color: #303030;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 15px;
}


.grp-scroller {
  float: left;
  width: 100%;
  min-height: 350px;
  max-height: 350px;
  overflow: auto;
}

.showuserslist {
  float: left;
  width: 100%;
  padding: 10px 7px;
  border-bottom: 1px solid #e1e1e1;
}

.detalunderline {
  border-bottom: 1px solid black;
  width: 40px
}

.grpfooter button:disabled {
  color: #afb0b0;
  border: 1px solid #bebebe !important;
  background: transparent;
  cursor: none !important;
}

.linkerror {
  margin-left: 15px;
}

span.engagelistflex.engageList {
  position: relative;
  top: 3px;
}

.engagelistflex.toppiceCommit {
  position: relative;
  top: 3px;
}

.iconsproj {
  float: left;
}

.projnameMedia {
  margin-left: 20px;
}

.underline {
  text-decoration: underline;
}

/*setting-detail-css*/


.headerformNew {
  text-align: center;
  padding: 12px 0px;
  background: #f03d3d;
  border-radius: 5px 5px 0px 0px !important;
  font-size: 18px !important;
  color: #fff;
}

.popup_dualbox.borderTopNon {
  border-radius: 5px !important;
}

.modelheader h2 {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.headerformNew h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.modelheader {
  padding: 15px 15px 0px 15px;
}

.height-150 {
  height: 150px !important;
  overflow: auto;
}

.borderTopNon+button.react-responsive-modal-closeButton svg {
  fill: #fff !important;
}

.flex_fixwidht {
  width: 450px !important;
}

.fleber {
  display: flex;
}

.ceterleft {
  float: left;
  display: flex;
  align-items: center;
}

.ceterleft.reply {
  margin-top: 3px;
}

/*----profile----*/
.mentorbox {
  margin-top: 20px;
  border-radius: 15px;
}

.mentorHeading {
  position: relative;
  display: inline-block;
  background: #6abca6;
  margin: 0 auto;
  padding: 3px 15px;
  border-radius: 15px;
  color: #fff;
  top: -12px;
  font-weight: 700;
  font-size: 14px;
  z-index: 1;
}

.metormain {
  padding: 0px 30px;
  margin-top: 30px;
}

.mentorbox p {
  margin: -5px 0px 0px;
  padding: 0px 0px 10px;
  font-weight: 600;
}

.newColorFn {
  position: relative;
  top: -29px;
  background: #46bfa5;
  color: #fff;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 6px;
}

.onLeaveFn {
  font-size: 22px;
  color: #ffa300;
  font-weight: 700;
}

.fxheight {
  position: relative;
  margin-bottom: 110px;
}

.scrollerport {
  height: 104px !important;
  overflow: auto;
  border-radius: 5px;
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid#ddd;
  z-index: 0;
  padding-top: 24px;
}

.mx-700 {
  width: 700px;
}

.manageUserPhoneFied {
  position: relative;
}

.manageUserPhoneFied .react-tel-input .flag-dropdown {
  width: 50px;
  border-radius: 4px 0 0 4px !important;
}

.manageUserPhoneFied input {
  width: 100% !important;
  height: 45px !important;
  padding-left: 60px !important;
  border-radius: 4px !important;
}

.manageUserPhoneFied input:focus,
.manageUserPhoneFied input:focus .react-tel-input .flag-dropdown {
  border-color: #46bfa5;
}

.manageUserPhoneFied .react-tel-input .selected-flag {
  width: 100%;
  padding: 6px;
  border-radius: 7px 0 0 7px !important;
}

.manageUserPhoneFied .react-tel-input .flag-dropdown {
  background-color: #ffffff;
}

.newform.organize.editmanagUsers.seterBox.fdformwidth.manageuserFormMax {
  max-width: 800px;
}

.interestmodal.adminIntersPannel.profilePopWidth.resnospaceright.setForInterest {
  max-width: 620px;
}

.fot-16 {
  font-size: 16px !important;
  font-weight: 400;
}

.w-49.heghtInput input {
  height: 45px !important;
}

span.setTextInfo {
  font-weight: normal;
  font-size: 16px;
}

.impDoc a {
  background: #eeeef0;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  color: #303030;
  transition: 0.3s;
}

.impDoc a:hover {
  background-color: #46bfa5;
  color: #fff;
}

.impDoc {
  float: right;
  margin-top: 8px !important;
}

.modal-footer {
  border-top: none !important;
}

.dflex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marNag {
  margin-top: 0px;
}

.marNag {
  margin-top: -10px !important;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flex-centerproject {
  display: block;
  text-align: center;
  margin-top: 30px;
  float: left;
  width: 100%;
}

.roundBtn button {
  border-radius: 50px !important;
  position: relative;
  top: 12px;
}

.cardPanneRight {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.customeRelativebox {
  position: relative;
}

.marg {
  margin-top: 15px !important;
}

.buttonsetonnav {
  float: right;
  position: relative;
  margin-top: -74px;
}

.buttonsetonnav.admin {
  top: 16px;
}

.btn-150 {
  width: 120px;
}

.spacer {
  margin-bottom: 0.5rem !important;
}

.cardPanneRight .card-title {
  font-size: 16px;
  margin: 0px 8px 0px 0px;
}

button.mb-2.marg-2.btn.btn-outline-primary {
  margin-right: 5px;
}

.d-flex.roundBtn.managersort button {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}

.padingbot-5 {
  margin: 0px 5px;
}

.cardgreenTop {
  height: 6px;
  margin-top: -6px;
  margin-bottom: 10px !important;
  background: #46bfa5 !important;
}

.nomb-10 {
  margin-bottom: 0px !important;
}

.roundedge {
  border-radius: 50px;
}

.btn-110 {
  width: 113px;
  margin-top: 0.35rem !important;
}

.sidebar.top-0.main-menu.sidebar_menu {
  background: #191919 !important;
}

.customeMenubar {
  width: 152px !important;
  background-color: #191919;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px 0;
  overflow: visible;
  position: relative;
}

.logoarea {
  width: 80px;
  height: 80px;
  // background: #fff;
  padding: 5px;
  text-align: center;

  img {
    object-fit: contain;
    width: 70px;
    height: 70px;
  }
}

.main-menu ul li a {
  color: #fff;
}

.main-menu ul li:hover a {
  color: #46bfa5;
}

.powerBy {
  position: absolute;
  bottom: 89px;
  z-index: 10000;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #191919;
  left: 0;
  width: 100%;
  padding: 10px 0;
}

.logoSpanText {
  font-size: 9px;
  color: #fff;
}

.powerdbylogo {
  width: 60px;
  height: 20px;
}

.loginR {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  height: 100vh;
  flex-direction: column;
}

.loginLink img {
  width: 250px;
}

.leftLoginForm {
  float: left;
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardForm.card {
  text-align: center;
  width: 375px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 30px 20px 40px 20px;
}

.forgotPass {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  margin-top: -10px;
  margin-left: 12px;
}

.forgotPass a {
  font-size: 12px !important;
}

.setPaddingsurvey {
  padding-left: 28px;
}

.MuiBox-root.css-0 {
  width: fit-content !important;
}

//dev
.setLauchpadDrowp .css-18nc3u2 .MuiAutocomplete-tag {
  max-width: calc(100% - 275px) !important;
}

.setLauchpadDrowp .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  max-width: calc(100% - 275px) !important;
}

.input-box-single.css-18nc3u2 .MuiAutocomplete-tag {
  max-width: calc(100% - 6px) !important;
}

.airtableicon {
  width: 44px;
  margin-right: 10px;
  margin-left: 15px;
}

.air {
  cursor: pointer;
  width: fit-content;
}

.pannelSettingHeader.intgration {
  padding: 16px 10px !important;
}

.topic-heading.setListData.sliceLeft p {
  padding-left: 49px;
}

.w-100.setListData.engageLeftparaSpace p {
  padding-left: 46px;
}

.survtablistbreack {
  border-right: 1px solid #303030;
  padding-right: 15px;
  margin: 10px 0px;
}


.editManageuserbtnsettop {
  position: relative;
  top: -37px;
}



.testassignheading {
  font-size: 16px;
  font-weight: bold;
}

.engageassinglist {
  margin: 0;
  padding: 0;
}

.engageassinglist li {
  display: block;
  list-style: none;
  font-size: 14px;
  position: relative;
  padding: 5px 0px;
}

.ënagelistassingIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.swithOnoff {
  float: right;
}




/*-----manageuser--new--design-----*/
.mun-head {
  float: left;
  width: 100%;
  display: flex;
}

.smakSearch {
  position: absolute;
  top: 10px;
  left: 10px;
}

.panneldateFilter label {
  font-size: 12px;
  margin: 0px 0px 3px 0px;
}

.dbp-setasearch {
  position: absolute;
  left: 7px;
  width: 15px;
  top: 9px;
}


.panneldateFilter {
  margin-bottom: 10px;
}

.panneldateFilter input {
  width: 100%;
  border: 1px solid #EEEEF0 !important;
  height: 34px;
  padding: 0px 0px 0px 27px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(231, 234, 240, 0.5);
  outline: none;
  font-size: 12px;
}

.mun-searchPnnel input {
  width: 100%;
  border: 1px solid #EEEEF0;
  height: 34px;
  padding: 0px 0px 0px 27px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(231, 234, 240, 0.5);
  outline: none;
}

.mun-searchPnnel {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mun-btnpannel {
  display: flex;
}

.mun-searchbox {
  position: relative;
  width: calc(100% - 194px);
}

.mun-filtermenu {
  float: left;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  opacity: 1;
}

.mun-literPannel h2 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.mun-greenbg {
  background-color: #46BFA5;
}

.mun-literPannel {
  padding: 9px 0px 3px 10px;
  border-radius: 0px 0px 0px 0px;
  line-height: 13px;
  cursor: pointer;
  margin-left: 5px;
}

.mun-icon {
  margin-left: 8px;
}

.topManPosi {
  position: relative;
  top: -1.5px;
}

// .mun-green {
//   color: #34A990;
// }

.filterPannel {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
}

.filterbox {
  display: flex;
  border: 1px solid #34A990;
  border-radius: 50px;
  padding: 0px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.filterbox span {
  margin-left: 3px;
  cursor: pointer;
}

.filterbox span:hover {
  opacity: .5;
}

.filterbox p {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: bold;
  color: #34A990;
}

.filterbox img {
  width: 12px;
}


.manageruserNewPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: #fff;
  border-radius: 5px;
  z-index: 1000;
}

.manageruserHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  border-bottom: 1px solid #ddd;
}

.manageruserHeader h2 {
  font-size: 16px;
  margin: 0px 0px;
  font-weight: bold;
}

.closePannel {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.inputOptionmanageUser label {
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.inputOptionmanageUser select {
  border: 1px solid rgba(173, 184, 196, .5);
  display: block;
  width: 100%;
  padding: 10px 8px;
  border-radius: 5px;
  outline: none;
}

.inputOptionmanageUser input {
  border: 1px solid rgba(173, 184, 196, .5);
  display: block;
  width: 100%;
  padding: 10px 8px;
  border-radius: 5px;
  outline: none;
}

.manageruserbody {
  padding: 10px 15px 0px 15px;
}

.mun-greentext {
  color: #46BFA5;
}

.text-underline {
  text-decoration: underline;
}

.inputOptionmanageUser {
  margin: 10px 0px;
}

.manageuser-t14 {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px;
}

.manageuser-t14:hover {
  opacity: 0.5;
}

.mun-sucess-btn {
  border: 1px solid#46BFA5;
  padding: 7px 20px;
  border-radius: 50px;
  background-color: transparent;
  color: #46BFA5;
}

.mun-sucess-btn:hover {
  background-color: #46BFA5;
  color: #fff;
}


.manageuserfooter {
  padding: 10px 15px 15px 15px;
  float: left;
  width: 100%;
}

.closePannel img {
  width: 18px;
}

.manageParaText {
  font-size: 14px;
  font-weight: 600;
  color: #303030;
  margin: 0;
}

.manageUserBackbtn {
  position: absolute;
  left: 15px;
  display: block;
}

.munfont-12 {
  font-size: 12px;
}

.munh-40 {
  height: 40px;
}


.tbodyScroll {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  display: block;
  height: 100%;
}

.mnu-16 {
  width: 16px;
}

.mnu-14 {
  width: 14px;
}

.mnu-13 {
  width: 13px;
}

.nu-20 {
  width: 20px;
}

.mun-literPannel:hover h2 {
  color: #17B298;
}

.mun-literPannel:hover .hovergreenicon {
  display: inline-block;
}

.mun-literPannel:hover .hovernorm {
  display: none;
}

.hovergreenicon {
  display: none;
}

.w100.sticky-col.sec-col.bg-grey {
  left: 0;
}


.first-col {
  left: 29px;
}



td.relativeTableCol.manageUserDotted.sticky-col.colone-col.bg-white.mbox.w100 {
  width: 100px !important;
}

.manageeditheading {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.input-box-single .MuiOutlinedInput-root {
  padding: 0px !important;
  min-height: 38px !important;
}

.manageUserPhoneFied input {
  height: 38px !important;
}

.editMasterProfile {
  float: left;
  width: 100%;
}

.editMasterProfile {
  float: left;
  width: 100%;
  margin-top: -10px;
}

.w308 {
  width: 308px !important;
}



.drowp.absluteTableCol {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 31px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-1px, -74%);
}

.nameUserbox {
  float: left;
  margin-left: 10px;
  margin-top: 2px;
}

.setleftSpacename {
  margin-left: 27px;
}

.setnamecol {
  left: 0px;
}

.coutText {
  font-size: 13px;
  color: #6c757e;
}

.coutText span {
  color: #6c757e;
}

.searchmanagerPannel {
  padding: 0px 0px 0px 36px !important;
  height: 38px !important;
}




.warringPoup {
  max-width: 500px;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50px);
  border-radius: 5px;
  z-index: 1000;
  background: #fff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
  text-align: center;
  overflow: hidden;
}

.warringpopupHeader {
  background: #dc3545;
  padding: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}

.wariningbody {
  padding: 20px;
}

.wariningbody h2 {
  font-size: 14px;
  font-weight: bold;
  color: #303030;
}

.btnwarring {
  padding: 7px 25px;
  font-size: 12px;
  border-radius: 50px;
  background-color: #dc3545;
  border: none;
  color: #fff;
  margin-top: 8px;
}

.btnwarring:hover {
  background-color: #e44b5a;
}


.alignemid {
  position: relative;
  top: 2px;
}

/*-----manageuser--new--design--end----*/
.manageuserTopspace {
  position: relative;
  top: 4px;
}

.settings-outlook {
  margin-top: 6px !important;
}

.settings-outlook img {
  width: 22px !important;
  margin-right: 12px !important;
}

.setAscallsurveypara {
  width: 75%;
}

.alternatexbox {
  width: 70%;
}

.altenbox .surwayborder.btn-small {
  width: auto !important;
}


.setAscallsurveypara {
  width: 75%;
}

.alternatexbox {
  width: 70%;
}

.assignheadingarea.project {
  margin: 0px 0px 16px;
  padding: 0px;
}

.headingMainfirst {
  font-size: 16px;
  font-weight: bold;
}

.spacealltop {
  margin: -7px 0 0 8px;
  position: relative;
}

.headingMainpannel {
  position: relative;
  float: left;
  width: calc(100% - 220px);
  display: block;
  margin: -33px 0px 0px -8px;
}

.resourcespacetopheading {
  margin: -4px 0px 0px 8px !important;
}


.projectspacePanel {
  margin: -33px 0px 0px 4px;
}


.projectbordspace {
  margin: -33px 0px 0px 5px;
}



/*----survey-----*/
.ag-cell-value.ag-cell-focus {
  overflow: visible;
}

.buttonbox.bottomUserbox {
  width: 20%;
}

span.smakSearch.manageuserSearch img {
  width: 17px;
}

.managuserfilter .filterBtn {
  width: 16%;
}

.allsurveyHeading.allsurveyheading {
  margin-left: 0px;
}

.symbaMainbtn:hover .iconBtn.nextPage.hover {
  display: inline-block;
}

.symbaMainbtn:hover .iconBtn.nextPage {
  display: none;
}

.setspacebutton {
  position: relative;
  top: 24px;
}


/*----all--newbtn-----*/
.centerflex {
  align-items: center;
}

.symbaMainbtn {
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #46BFA5;
  transition: 0.5s;
  cursor: pointer;
  color: #46BFA5;
  background-color: #fff;
}

.symbaMainbtn:hover {
  background: #46BFA5;
  color: #fff;
}

.symbafgreebtn {
  background: #46BFA5;
  color: #fff;
  transition: 0.5s;
}

.symbafgreebtn:hover {
  background-color: #34A990;
}


.symbabgmainbtn {
  width: 100%;
}

.symbabgmainbtn:hover {
  background-color: #34A990;
  color: #fff;
}

.symbagraybtn {
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #4C5F7E;
  transition: 0.5s;
  cursor: pointer;
  color: #4C5F7E;
  background-color: #fff;
}

.symbagraybtn:hover {
  background-color: #4C5F7E;
  color: #fff;
}


.symbapopupFullbtnGreenborder {
  width: 100%;
  padding: 8px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #46BFA5;
  transition: 0.5s;
  cursor: pointer;
  color: #46BFA5;
  background-color: #fff;
}

.symbapopupFullbtnGreenborder:hover {
  background-color: #46BFA5;
  color: #fff;
}


.symbapopupFullbtngreyborder {
  width: 100%;
  padding: 8px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #9e9e9e;
  transition: 0.5s;
  cursor: pointer;
  color: #535252;
  background-color: #fff;
}

.symbapopupFullbtngreyborder:hover {
  background-color: #4C5F7E;
  color: #fff;
}

.symbaIconbtn {
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #dc3545;
  transition: 0.5s;
  cursor: pointer;
  color: #dc3545;
  background-color: #fff;
}

.symbaIconbtn:hover {
  background-color: #dc3545;
  color: #fff;
}



.dletefullbtn {
  padding: 8px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dc3545;
  transition: 0.5s;
  cursor: pointer;
  color: #dc3545;
  background-color: #fff;
  width: 100%;
}

.dletefullbtn:hover {
  background-color: #dc3545;
  color: #fff;
}


.symbaFullRedbtn {
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  color: #fff;
  background-color: #dc3545;
  border: none
}

.symbaFullRedbtn:hover {
  background-color: #c22636;
}

.symba-w110 {
  width: 110px;
}

.symba-w90 {
  width: 90px;
}


.symbabuttonIcon {
  font-size: 12px;
  margin-right: 5px;
}

.relativeSpace10 {
  position: relative;
  top: 10px;
}

.symbaBackborderbtn {
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #9e9e9e;
  transition: 0.5s;
  cursor: pointer;
  color: #535252;
  background-color: #fff;
}

.symbaBackborderbtn:hover {
  background-color: #535252;
  color: #fff;
}

.button80Width {
  width: 80px;
}


.footerschedul {
  float: left;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 15px;
}

.symbaMainbtn.symbabgmainbtn:disabled {
  opacity: 0.5;
}

.symbapopupFullbtnGreenborder:disabled {
  opacity: 0.5;
}

.improbtnspace {
  margin-top: 16px;
}

.symbagraybtn.setheightbtn.disabled {
  opacity: 0.5;
}

.symbaMainbtn em {
  color: #46bfa5;
}

.symbaMainbtn:hover em {
  color: #fff;
}

.sethightbtnproject {
  height: 31.6px;
}

.projectbtnmt-2 {
  margin-top: 7px;
}

.admincolxx.projectdetails .css-17o5nyn {
  margin: 6px 0 !important;
}

.admincolxx.projectdetails .css-1iji0d4 {
  min-height: 34px !important;
}

.flex-projectcenter {
  float: left;
  width: 100%;
  text-align: center;
  padding: 32px 0px 0px;
}

.symbapopupFullbtnGreenborder:disabled {
  opacity: 0.5;
}

/*----all--newbtn--end-----*/

.resourcebtnback {
  position: relative;
  top: -35px;
}

.back-button.topic.d-flex i {
  top: 0;
}

.cursor-pointer.headingSwitchTop.mt-nagitave.reourcebtnback {
  top: 7px;
}

.setclosefolder {
  top: 8px !important;
  right: 8px !important;
}

.folderCretorBox {
  width: 500px;
  padding: 15px;
}

.folderbuttonarea {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px;
}


.dublicateicon {
  margin-left: -4px;
  margin-right: 5px;
}


.symbaMainbtn:hover a {
  color: #fff;
}

.profileInput {
  padding: 7px 10px;
}

.profileLeable {
  font-size: 14px;
  margin: 20px 0px 4px 0px;
}

.requestlevetbtnarea {
  margin-top: 70px;
  float: left;
  width: 100%;
}

.datepicforSurve.leavereCal {
  margin: 0;
  width: 100%;
}

.requestLeaveBody .journeyInput.journeyBottommargin {
  margin-bottom: 15px;
  margin-top: 0;
}

.mtop-15 {
  margin-top: 15px !important;
}

.r-marpadd {
  margin: 0px !important;
  padding: 0px !important;
}

.retundate {
  font-weight: bold;
  font-size: 14px;
}

.setmarginiconpro {
  margin-right: 10px;
  margin-left: 1px;
}

.cursor-pointer.BackbuttonSwitchTop.interprojecthback.projectienter {
  left: -31px !important;
}

span.imagePictureProject.searcproj.lanceprojectinte {
  top: 10px;
}

.relativebox.actiontimesetor {
  float: left;
  width: 86px;
  top: -10px;
}

.marg0pro {
  top: -3px;
}

.terimg {
  width: 130px;
  margin: 0 auto;
}


.terminarea {
  margin: 20px 0px;
}

.myPosition {
  position: absolute;
  right: -10px;
  font-size: 14px;
  width: 150px;
  top: 17px;
}

.greenfont,
.redbrn {
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
}
.greenfont{
  color: #46bfa5;
}
.redbrn {
  color: red;
}



.redbrn:hover,
.greenfont:hover {
  opacity: 0.5;
}




span.infoIcon.dabsol .toolTip {
  top: -68px !important;
  left: -90px !important;
}


.drelative {
  position: relative;
}

.dabsol {
  left: -73px !important;
  top: -13px !important;
}

.rightwhiteinfo {
  margin-left: 4px;
  top: -1px !important;
  position: relative !important;
  left: 0;
}

.selectproject {
  width: 100%;
  border-color: #ddd;
  color: #565656;
  outline: none;
  cursor: pointer;
}

.setmargin {
  margin: 0px;
}

label.assignHeading.setheadingproj {
  padding: 0;
  margin: 0;
  position: relative;
  top: 0px;
}

.ifthemCheck {
  float: left;
  margin-left: 30px;
  margin-top: 1px;
}

.fx-flex-aligment {
  margin: 10px 0px 31px;
}

label.chk.float-right.fixspacen {
  width: 96px;
}

@media screen and (max-width: 992px) {
  .searchResourcefolder {
    width: 80% !important;
  }

  .searAraeresource input {
    width: 100% !important;
  }


  .searAraeresource {
    width: calc(100% - 117px) !important;
  }

  .droppable {
    width: 51% !important;
  }




}

@media screen and (max-width: 768px) {
  .resourcelist {
    width: 67px;
    height: 67px;
    left: 0px;
    top: 7px;
  }


  .loginR {
    display: none;
    width: 100%;
  }

  .leftLoginForm {
    width: 100%;
    float: none;
  }

  .conformalert {
    width: 95%;
  }

  .heig {
    height: 55px !important;
  }

  .impDoc {
    float: right;
    margin-top: 30px !important;
  }

  .profileMid {
    float: left;
    width: 100%;
    margin-top: 60px;
  }

  .newColorFn {
    top: -22px;
  }

  .assignSelect {
    margin-top: 15px !important;
    margin-bottom: 20px;
  }

  .padertopbottom10 {
    width: 100% !important;
  }

  .launchResorceBox {
    padding: 4px !important;
  }

  .has-search .form-control {
    padding: 9px 0px 9px 35px;
    border-radius: 7px;
    margin-bottom: 10px;
  }

  .set-40.rearrangebtn .saveBtn {
    margin-bottom: 10px;
  }


}



@media screen and (max-width: 565px) {
  .float-right.respfull button {
    margin-right: 10px;
  }

  .float-right.respfull {
    width: 100%;
    text-align: center;
    float: left !important;
    display: flex;
    align-items: center;
  }

  .flexcustome {
    flex-direction: column !important;
  }

  .searAraeresource {
    width: 100% !important;
  }

  .droppable {
    width: 100% !important;
  }

  .searchResourcefolder {
    width: 100% !important;
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 10px;
  }

  .searchResourcefolder h2 {
    padding-bottom: 8px;
  }

  .searAraeresource input {
    width: 100% !important;
    margin: 0px 0px !important;
  }

  .searAraeresource span {
    top: 12px !important;
    left: 7px !important;
  }


  .cardForm.card {
    width: calc(100% - 30px);
  }

  .label-eng {
    margin-top: 35px;
  }

  .ibt {
    padding-top: 15px;
  }

  .unflux {
    flex-direction: column;
  }

  .launchResorceBox {
    padding: 12px 0px;
  }

  .iconBox {
    top: 9px;
    left: 4px;
  }

  .setboxIcon {
    right: 5px;
    top: 17px;
  }

  .iconBox.resourcelist {
    display: none;
  }

  .launchBoxBody {
    border-radius: 6px;
  }

  .iconBox {
    display: none;
  }

  .launchBoxRight {
    margin-left: 0px;
    margin-top: 13px;
  }

  .launchBoxRight h2 {
    margin: 4px 0px 0px 5px;
  }

  .padertopbottom10 {
    width: initial;
  }

  .imgFigure {
    right: 13px;
  }

  .p0 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .toggleslack .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    width: 261px !important;
  }

  .engageMargintop {
    margin-top: 40px;
  }

  .resfullwidth {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
  }

  .respinsivemt-2 {
    margin-top: 10px;
  }

  .dropdown-menu.show {
    left: -10px !important;
  }

  .dropdown.show {
    & .eng-topic.dropdown-menu.show {
      // left: -131px !important;
      transform: translate3d(-139px, 43px, 0px);
    }
  }

  .settinglogoHeading {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
  }

  .pannelSettingHeader {
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ml-60 {
    margin-left: 60px;
  }

  .settingSavebtn {
    background: transparent;
  }

  .pannelSettingBody {
    border-radius: 5px;
    padding: 10px 15px;
    height: 335px;
  }

  .formGridSetting input {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .formGridSetting {
    position: relative;
    text-align: center;
    padding: 25px 0px;
    display: block;
  }

  .formGridSetting span {
    font-size: 135px;
    text-align: center;
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  .formGridSetting b {
    display: block;
  }

  .formGridSetting p {
    font-size: 16px;
    margin: 5px 0px;
    padding: 0;
  }

  .bioQuationGrid.mt-2 p {
    padding: 0px;
    margin: 0;
  }

  /*tablenew*/
  .newTable {
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
  }


  table.managerUser thead {
    background: #eeeef0;
    display: block;
  }

  table.managerUser thead tr td {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 24px;
    color: #5e5e5e;
    text-transform: capitalize;
    font-weight: 900;
    width: 190px;
    display: inline-block;
  }

  table.managerUser tbody tr td {
    border-bottom: 1px solid#ddd !important;
    padding: 15px;
    font-size: 14px;
    line-height: 24px;
    color: #6c757e;
    font-weight: 900;
    width: 190px;
  }

  .manageUserDotted {
    width: 50px !important;
  }

  .w-inherit {
    width: inherit !important;
  }

  table.managerUser tbody {
    border-left: 1px solid#ddd;
    border-right: 1px solid#e7e7e7ab;
  }

  .relativeTableCol {
    position: relative;
  }

  .drowp.absluteTableCol {
    position: absolute;
    top: 0;
    right: 14px;
  }

  .manageUserleftSpace {
    margin-left: 27px;
    z-index: 10000;
  }

  .nomargin .dropdown-menu {
    padding: 0px !important;
  }

  th.startdate.setWidth {
    width: 100px;
  }

  th.enddate.setnewend {
    width: 125px;
  }

  /*tablenew*/
  .cummunityPannel textarea {
    width: 100%;
    height: 150px;
    border: 1px solid#ddd;
    border-radius: 5px;
    font-size: 13px;
  }

  .cummunityPannel p {
    padding: 0px;
    margin: 0;
  }

  .settingFigure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: #fff;
  }

  .settingFigure img {
    width: 200px;
  }

  .customeClose {
    z-index: 99;
  }

  .reltext {
    display: none;
  }

  img.frontimg {
    height: 280px;
    object-fit: contain;
  }

  span.newClose.customeClose {
    padding: 3px;
  }

  .centertax {
    height: 279px;
  }

  .centertax img {
    object-fit: contain;
  }

  .closeQestion {
    height: 41px;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e01a4f;
    color: #fff;
    position: relative;
    top: 4px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .closeQestion svg {
    fill: #fff !important;
  }

  .closeQestion:hover svg {
    opacity: 0.7;
  }

  .autoHeight {
    height: auto;
  }

  .wfFlexBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .saveBtn.btn-save.float-right.mt-3.squarebtn {
    border-radius: 6px;
  }

  .react-datepicker__day--disabled {
    opacity: 0.6;
  }

  .mainFlexWidth {
    max-width: 650px;
  }

  .chekall {
    padding: 2px 0;

    &:hover {
      color: $greenColor;
    }
  }

  .heig {
    height: 55px !important;
  }

  .interestmodal.adminIntersPannel.profilePopWidth.resnospaceright.setForInterest {
    width: 320px;
  }

  .mx-700 {
    width: 320px;
  }

  .buttonbox {
    float: none;
    width: 100%;
    margin-top: 10px;
  }

  .bioQuationGrid input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid#ddd;
    outline: none;
  }

  .settingpannel {
    padding: 20px 0px 50px;
  }

  .communitySavebtn {
    height: 35px;
    line-height: 17px;
  }

  .resnospaceright {
    padding-right: 0px;
  }

  .setPaddingboddy {
    padding: 15px;
  }

  .profile_name_text {
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .editArea {
    margin-left: -10px;
  }

  .flex_fixwidht {
    width: 320px !important;
  }

  .fleber {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fleber .commentBtn {
    font-size: 12px;
  }

  .fleber .topiccommentlike {
    padding: 4px 5px;
  }

  .fleber .ceterleft:nth-child(2) {
    margin-top: 5px;
  }

  .subcommentlikebuttonmargin {
    margin-bottom: 10px;
  }

  .impDoc {
    float: right;
    margin-top: 14px !important;
    margin-bottom: 10px;
    width: 100%;
  }

  .impDoc a {
    width: 100% !important;
    display: block;
    text-align: center;
  }

  .cursor-pointer.headingSwitchTop.mt-nagitave.reourcebtnback {
    top: -49px;
  }


}



@media screen and (max-width: 500px) {
  .add-new {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}


@media screen and (max-width: 400px) {
  .dx-pager .dx-pages {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fs-full {
    min-width: 333px !important;
  }
}